@font-face {
    font-family: 'Gilroy';
    font-style: normal;
    src: local('Gilroy-Light'), url('../fonts/Gilroy-Light.otf') format('opentype');
}

@font-face {
    font-family: 'Gilroy';
    font-weight: bold;
    src: local('Gilroy-ExtraBold'), url('../fonts/Gilroy-ExtraBold.otf') format('opentype');
}

* {
    font-size: 14px;
    font-family: 'Gilroy-light', 'Gilroy', 'Arial', monospace;
    color: #3d3d3d;
    list-style: none;
    padding: 0;
    margin: 0;
}

.shop-image img, .product-image img, .modal-product-image img, .cart-product-image img, .home-main-image img, .header-brand-image img,
.header-cart-image img, .slider-element img, .modal-container-city img, .modal-sidebar img, .footer-image img, .success-image img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.modal-container {
    background:rgba(0,0,0,0.5);
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
}

.modal-sidebar {
    width: 375px;
    height: 100%;
    background: #fff;
    padding: 10px;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
}

.modal-container-city {
    max-width: 800px;
    max-height: 530px;
    background: #fff;
    padding: 50px;
    position: fixed;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
}

.modal-container-product {
    max-width: 800px;
    max-height: 860px;
    padding: 50px;
    background: #fff;
    position: fixed;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 999;
    overflow: auto
}

@media (max-width: 480px) {
    .modal-container-product {
        max-height: 560px;
    }
}

.modal-container-product button{
    cursor: pointer;
    float: right;
}

.modal-product-image {
    width: 290px;
    height: 290px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.city-image {
    width: 50px;
    height: 50px;
    margin-left: 15px;
}

.modal-container-city label {
    font-size: 20px;
    display: flex;
    align-items: center;
}

.modal-container-city ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.modal-container-city ul a {
    font-size: 16px;
}

.home-header label {
    font-size: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.city-categories-list, .home-header .row {
    margin: 10px 10px 0 10px;
    display: flex;
    align-items: center;
    align-content: center;
}

.city-categories-list label {
    margin-left: 20px;
    font-size: 20px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.city-categories-list img {
    background: #fff;
    padding: 10px;
    width: 80px;
    height: 80px;
    object-fit: contain;
    box-shadow: 0px 2px 4px rgba(117, 131, 142, 0.04), 0px 8px 16px rgba(52, 60, 68, 0.1);
    border-radius: 10px;
}

.home-topside {
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.home-topside a {
    border:  1px solid #E0E0E0;
    text-decoration: none;
    min-width: 250px;
    width: 250px;
    margin: 10px;
    border-radius: 10px;
}

.home-topside a:hover {
    background: #E0E0E0;
}

.home-topside label {
    font-size: 18px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    cursor: pointer;
}

.home-topside img {
    background: #fff;
    padding: 10px;
    width: 100%;
    height: 80px;
    border-radius: 10px 10px 0 0;
    object-fit: contain;
}

.modal-product-info {
    display: flex;
    justify-content: space-between;
    border-bottom:  1px solid #E0E0E0;
    padding: 10px 0 10px 0;
}

.cart-price-info, .shop-header-info, .total-price-info {
    display: flex;
    justify-content: space-between;
}

.total-price-info label {
    font-weight: bold;
    font-size: 22px;
}

.cart-price-info .price, .show-more-text, .cartitems-right-block  {
    text-align: right;
}

.header-container {
    background: #fff;
    width: 100%;
    height: 80px;
    position: fixed;
    z-index: 999;
    box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    align-content: center;
}

.header-container nav {
    display: flex;
    justify-content: space-between;
}

.header-brand-image {
    max-width: 200px;
}

.body-container {
    padding-top: 120px;
    min-height: calc(100vh - 200px);
}

.footer-container {
    background: rgba(237, 237, 237, 0.5);
    height: 200px;
    width: 100%;
    display: flex;
    align-items: center;
    align-content: center;
}

.footer-info {
    float: right;
}

.footer-image {
    width: 206px;
    height: 66px;
    margin-bottom: 20px;
}

.not-found-container, .success-container {
    display: grid;
    justify-content: center
}

.not-found-image, .success-image {
    width: 100px;
    height: 100px;
    margin: 0 auto 10px auto;
}

.not-found-container label, .success-container label {
    font-weight: bold;
    font-size: 32px;
}

.header-select-city {
    width: 100%;
    display: flex;
    justify-content: center;
}

.header-select-city .dropdown {
    box-shadow: 1px 1px 10px -1px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px 6px 10px;
    width: 200px;
    height: 50px;
}

.header-select-city .dropdown label {
    padding-top: 6px;
    width: 150px;
    font-size: 15px;
    cursor: pointer;
}

.header-select-city .dropdown svg {
    width: 20px;
    height: 10px;
    fill: none;
    stroke: #3d3d3d;
    cursor: pointer;
}

.header-toggler {
    border: 0;
}

.button-add-to-cart {
    width: 50px;
    height: 45px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    position: absolute;
    border-radius: 50%;
    margin-left: 18em;
    cursor: pointer;
    margin-top: 135px;
}

.modal-add-to-cart {
    border-color: #B0CB1F;
    border-style: 3px;
}

.checkout-done-button {
    border-color: #B0CB1F;
    border-style: 3px;
    margin-top: 40px;
}

.modal-add-to-cart:hover, .checkout-done-button:hover {
    background-color: #99AE27;
    color: #fff;
}

.modal-add-specification {
    font-size: 22px;
    background-color: #B0CB1F;
    color: #fff;
    width: 22px;
    height: 22px;
    padding: 1px;
    margin: 0;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.modal-label-quantity {
    width: 30px;
    font-size: 16px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.cart a label, .card-shop-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.cart-add-product, .specification-name {
    font-size: 20px;
}

.cart-add-button-product {
    width: 35px;
    height: 35px;
    margin: 0 20px 0 20px;
    padding: 0;
    font-size: 20px;
}

.button-add-to-cart:hover {
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.1s ease-out;
}

.carts {
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 8px 16px rgba(0, 0, 0, 0.1);
}

.grid-container {
    display: grid;
    grid-gap: 20px 20px;
    grid-template-columns: repeat( auto-fill, minmax(345px, 345px));
    justify-content: center;
}

.card-product {
    height: 400px;
    width: 345px;
    border-radius: 20px;
    box-shadow: 0px 2px 4px rgba(117, 131, 142, 0.04), 0px 8px 16px rgba(52, 60, 68, 0.1);
    cursor: pointer;
    padding: 20px;
}

.card-shop {
    height: 315px;
    width: 345px;
    border-radius: 20px;
    box-shadow: 0px 2px 4px rgba(117, 131, 142, 0.04), 0px 8px 16px rgba(52, 60, 68, 0.1);
    cursor: pointer;
}

.card-shop:hover, .card-product:hover {
    box-shadow: 5px 10px 10px rgba(0, 0, 0, 0.3);
    transition: all 0.1s ease-out;
}

.card-shop-info {
    padding: 20px;
}

.shop-working-status {
    width: 100px;
    height: 50px;
    background-color: #fff;
    border-radius: 20px 0 0 0;
    position: absolute;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
}

.shop-working-status label, .shop-header-image, .modal-specifications-buttons {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.shop-image, .product-image {
    height: 200px;
    padding: 10px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.product-name {
    margin-bottom: 10px;
    font-size: 18px;
}

.modal-product-name {
    font-size: 20px;
    font-weight: bold;
}

.product-info {
    height: 120px;
}

.product-price {
    font-size: 22px;
}

.product-fake-price {
    text-decoration-line: line-through;
    margin-left: 10px;
    font-size: 18px;
    height: 100%;
}

.shop-header-image img  {
    max-width: 230px;
    object-fit: cover;
}

.cart-product-image {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    align-content: center;
}

.suggestions-image {
    height: 225px;
    width: 175px;
    border-radius: 20px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-flow: row nowrap;
}

.cart-item-main-title, .checkout-title-block {
    font-size: 22px;
    font-weight: bold;
}

.cart-title-organization {
    height: 60px;
    background-color: #B0CB1F;
    padding: 10px;
    display: grid;
    grid-template-columns: 40px 8fr 110px;
    grid-gap: 20px;
    border-radius: 8px;
}

.cart-title-organization span {
    background-color: #EDEDED;
    border-radius: 4px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 40px;
}

.cart-organization-name {
    font-size: 16px;
    font-weight: bold;
    padding: 10px 0 0 0;
}

.header-cart-image {
    height:25px;
    width: 25px;
    margin-right: -5px;
    margin-bottom: -20px;
}

.header-total-price {
    background-color: #B0CB1F;
    border-radius: 30%;
    padding: 2px 4px 2px 4px;
    color: #fff;
}

.page-item {
    margin: 30px 5px 0 5px;
}

.page-link, .page-link:hover {
    color: #000;
    border: none;
    border-radius: 3px;
}

.active a {
    border: 1px solid #EDEDED !important;
    color: #000 !important;
    background-color: #EDEDED !important;
}

.tabs-title, .switch-title {
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.tabs-title button, .switch-title button{
    min-width: 180px;
}

.shop-header-info div {
    margin: 10px;
}

.shop-header-info label {
    margin: 10px 0 0 0;
}

.shop-header-name {
    font-size: 26px;
    margin: 10px;
    font-weight: bold;
}

.shop-header {
    max-height: 400px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.shop-navbar {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    margin: 20px 0 -23px 0;
}

.shop-navbar a:hover, .shop-navbar a:focus, .shop-navbar a:visited,
.shop-category-link:hover, .shop-category-link:focus, .shop-category-link:visited  {
    text-decoration: none;
    border-bottom: 3px solid #B0CB1F;
}

.shop-navbar .active, .shop-categories .active  {
    border-bottom: 3px solid #B0CB1F;
}

.shop-categories a, .shop-subcategories a, .card-shop a, .card-product a {
    text-decoration: none;
}

.shop-navbar a {
    text-decoration: none;
    margin: 0 10px 0 10px;
    font-size: 20px;
}

.shop-category-link {
    padding: 10px 0 0 0;
    border-bottom: 1px solid #E0E0E0;
    font-size: 16px;
}

.shop-subcategory-link {
    padding: 10px 0 0 20px;
    border-bottom: 1px solid #E0E0E0;
    font-size: 16px;
}

.shop-subcategory-link:hover, .shop-subcategory-link:focus, .shop-subcategory-link:visited {
    border-bottom: 3px solid #B0CB1F;
}

.slider {
    position: relative;
    width: 100%;
    display: flex;
    max-height: 566px;
    overflow: hidden;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.slider-arrows {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

.slider-element {
    width: 100%;
}

.slider-prev {
    transform: translateX(-100%);
    background-color: #fff;
    display: none;
}

.slider-next {
    transform: translateX(100%);
    background-color: #fff;
    display: none;
}

.slider-arrow-left {
    font-size: 35px;
    padding: 5px 15px 0 15px;
    opacity: 0.5;
    cursor: pointer;
    margin-left: 10px;
    color: #3d3d3d;
    background-color: #fff;
    border-radius: 20%;
}

.slider-arrow-right {
    font-size: 35px;
    padding: 5px 15px 0 15px;
    opacity: 0.5;
    cursor: pointer;
    margin-right: 10px;
    color: #3d3d3d;
    background-color: #fff;
    border-radius: 20%;
}

.slider-arrow-left:hover, .slider-arrow-right:hover, .slider-dot:hover {
    color: #fff;
    background-color: #3d3d3d;
    opacity: 0.6;
}

.slider-dots {
    position: absolute;
    bottom: 0px;
}

.slider-dots a {
    padding: 20px 10px 10px 10px;
    cursor: pointer;
}

.slider-dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #fff;
    opacity: 0.5;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 1);
}

.slider-dot-active {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #000;
    opacity: 0.5;
    cursor: pointer;
    box-shadow: 0 2px 6px rgba(255, 255, 255, 1);
}

.tab-courier textarea, .tab-pickup textarea {
    height: 55px;
    resize: none;
}

.icon-search {
    width: 20px;
    height: 20px;
    position: absolute;
    margin: 14px 0 0 10px;
}

.input-search {
    box-shadow: inset 0px 0px 10px rgba(0,0,0,0.1);
    height: 50px;
    border: none;
    border-radius: 5px;
    padding-left: 40px;
}

.pagination, .checkout-done-info {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
}